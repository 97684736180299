import Wave from "react-wavify";
import menu from "../../assets/Menukaart_BarElvire_V9-2-1.png";

function Menu() {
  return (
    <div id="menu" className="bg-[#f8f6e3]">
      <div className="bg-[#9a3415]">
        <Wave
          fill="#f8f6e3"
          paused={true}
          style={{ display: "flex" }}
          options={{
            height: 70,
            amplitude: 50,
            speed: 0.15,
            points: 3,
          }}
        />
      </div>
      <div className="px-4 bg-[#f8f6e3] max-w-6xl m-auto">
        <div className="md:w-1/2 m-auto">
          <h1
            className={"font-black text-5xl text-center pb-10 text-[#9a3415]"}
          >
            Menu
          </h1>
          <p className={"font-normal text-xl text-center pb-10 text-[#9a3415]"}>
            Dit is onze standaard menukaart. Er staat nog meer op de kaart als
            je bij ons komt eten.{" "}
          </p>
          <p className={"font-normal text-xl text-center pb-10 text-[#9a3415]"}>
            De keuken sluit alle dagen om 15:00.{" "}
          </p>
          <p className={"font-normal text-xl text-center pb-10 text-[#9a3415]"}>
            Take away van bepaalde drankjes en gerechten mogelijk, vraag ernaar.{" "}
          </p>
        </div>
        <img
          src={menu}
          alt="Menukaart"
          className="w-full hidden md:block"
        />

        {/* Download Link (Visible on small screens) */}
        <div className="md:hidden text-center mt-4">
          <a
            href="/Menukaart_BarElvire_V9-2.pdf"
            download
            className="text-[#9a3415] underline text-lg font-medium"
          >
            Download de menukaart (PDF)
          </a>
        </div>
      </div>
    </div>
  );
}

export default Menu;
