// import React, { useState } from "react";
import Wave from "react-wavify";

const Contact: React.FC = () => {
    // const [isOpen, setIsOpen] = useState(false);

    return (
        <div id="reserveer" className="bg-[#9a3415]">
            <div>
                <div id="contact" className={"bg[#d0d4b4]"}>
                    <Wave fill='#d0d4b4'
                          paused={true}
                          style={{display: 'flex'}}
                          options={{
                              height: 70,
                              amplitude: 50,
                              speed: 0.15,
                              points: 3
                          }}
                    />
                </div>
            </div>
            <div className="bg-[#d0d4b4]">
                <div className="max-w-6xl mx-auto px-4">
                    <div className="">
                        <div className="md:w-1/2 m-auto">
                            <h1 className={"font-black text-5xl text-center text-[#9a3415]"}>Contact</h1>
                        </div>
                        <div className="grid md:grid-cols-2 grid-cols-1 px-4 rounded-xl">
                            <div className="text-center p-5 ">
                                <div className="space-y-1">
                                    <h4 className="font-bold text-3xl text-center pt-5 pb-5 text-[#9a3415]">Openingsuren</h4>
                                </div>
                                <div className="font-normal text-xl text-[#9a3415] grid grid-cols-2 gap-2">
                                    <div>
                                        Maandag
                                    </div>
                                    <div>9:30 - 16:00</div>
                                    <div>
                                        Dinsdag
                                    </div>
                                    <div>Gesloten</div>
                                    <div>
                                        Woensdag
                                    </div>
                                    <div>9:30 - 16:00</div>
                                    <div>
                                        Donderdag
                                    </div>
                                    <div>Gesloten</div>
                                    <div>
                                        Vrijdag
                                    </div>
                                    <div>9:00 - 17:00</div>
                                    <div>
                                        Zaterdag
                                    </div>
                                    <div>9:00 - 17:00</div>
                                    <div>
                                        Zondag
                                    </div>
                                    <div>9:30 - 17:00</div>
                                </div>
                            </div>
                            <div className="md:grid md:grid-cols-1 text-center p-5">
                                <div className="space-y-1">
                                    <h4 className="font-bold text-3xl text-center pt-5 pb-5 text-[#9a3415]">Contacteer
                                        ons</h4>
                                </div>
                                <div className="font-normal text-xl text-[#9a3415] md:grid md:grid-cols-1 gap-2">
                                    <div className="font-bold">
                                        Email
                                        <div className={"font-normal"}>
                                            contact@bar-elvire.be</div>
                                    </div>

                                    {/*<div className="font-bold items-center">*/}
                                    {/*    Telefoon*/}
                                    {/*    <div className={"font-normal"}>*/}
                                    {/*        +32 412 34 56 78</div>*/}
                                    {/*</div>*/}

                                    <div className="font-bold">
                                        Adres
                                        <div className={"font-normal"}>
                                            Gemeenteplein 22 2820 Rijmenam, België
                                        </div>
                                    </div>


                                </div>

                            </div>
                            <div className={"rounded-xl md:col-span-2 pb-5"}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d627.6937030279743!2d4.584088348212023!3d51.00183329310764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3e181fabde14f%3A0x7cc409d71cf8b4f3!2sGemeenteplein%2022%2C%202820%20Bonheiden%2C%20Belgium!5e0!3m2!1sen!2sus!4v1714671474793!5m2!1sen!2sus"
                                    title="Insluiting van een interactieve kaart"
                                    height={300}
                                    className={"w-full rounded-xl"}
                                    allowFullScreen={false} loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Contact;
