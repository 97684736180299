import Contact from "./components/page/Contact";
import Footer from "./components/page/Footer";
import Landing from "./components/page/Landing";
import MenuPdf from "./components/page/MenuPdf";
import Navigation from "./components/page/Navigation";
import Over from "./components/page/Over";
import Reserveer from "./components/page/Reserveer";

// import Waiting from "./components/page/Waiting";


function App() {
    return (
        // <Waiting/>
        <div className="bg-background scroll">
            <Navigation/>
            <div className="mx-auto px-4">
                <Landing/>
            </div>
            <Over/>
            {/* <Menu/> */}
            <MenuPdf/>
            <Reserveer/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default App;
